var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", { staticClass: "wscroll" }, [
        _c("h2", { staticClass: "content_title" }, [_vm._v("E/L")]),
        this.polCtrCd === "ID"
          ? _c(
              "table",
              { staticClass: "tbl_col", staticStyle: { width: "100%" } },
              [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.048")))]),
                    _c("th", [_vm._v("License No.")]),
                    _c("th", [_vm._v("License Date")]),
                    _c("th", [_vm._v("Customs Office")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.statusNm))]),
                      _c("td", [_vm._v(_vm._s(item.elNo))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.isNotEmpty(item.elNoValdDt) &&
                              item.elNoValdDt.length >= 6
                              ? _vm.$ekmtcCommon.changeDatePattern(
                                  item.elNoValdDt
                                )
                              : item.elNoValdDt
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.idCstmCd))]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        this.polCtrCd !== "ID"
          ? _c(
              "table",
              { staticClass: "tbl_col", staticStyle: { width: "100%" } },
              [
                _c("colgroup"),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.048")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL220.002")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK100")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.012")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.013")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ARAI100_M1.006")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.077")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.078")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.079")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK035")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.statusNm))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.isNotEmpty(item.pkgCd)
                              ? item.elNo
                              : ""
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.pkgQty))]),
                      _c("td", [_vm._v(_vm._s(item.pkgNm))]),
                      _c("td", { staticClass: "text_right" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.changeNumberFormat(item.grsWt, {
                              isComma: true,
                            })
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.prtlLdgYn))]),
                      _c("td", [_vm._v(_vm._s(item.ldgTcnt))]),
                      _c("td", [_vm._v(_vm._s(item.pkgtgCd))]),
                      _c("td", [_vm._v(_vm._s(item.pkgtgPkgQty))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.isNotEmpty(item.elNoValdDt) &&
                              item.elNoValdDt.length >= 6
                              ? _vm.$ekmtcCommon.changeDatePattern(
                                  item.elNoValdDt
                                )
                              : item.elNoValdDt
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }