<template>
  <div class="wscroll" v-if="viewFlag">
    <h2 class="content_title">E/L</h2>
    <table class="tbl_col" style="width:100%" v-if="this.polCtrCd === 'ID'">
      <colgroup>
        <col style="width:15%">
        <col style="width:30%">
        <col style="width:25%">
        <col style="width:30%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.048') }}</th>
          <th>License No.</th>
          <th>License Date</th>
          <th>Customs Office</th>
          <!--<th></th>-->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.statusNm }}</td>
          <td>{{ item.elNo }}</td>
          <td>{{ $ekmtcCommon.isNotEmpty(item.elNoValdDt) && item.elNoValdDt.length >= 6 ? $ekmtcCommon.changeDatePattern(item.elNoValdDt) : item.elNoValdDt }}</td>
          <td>{{ item.idCstmCd}}</td>
         <!-- <td>Compulsory Item only for Indonesian Export B/L</td> -->
        </tr>
      </tbody>
    </table>
    <table class="tbl_col" style="width:100%" v-if="this.polCtrCd !== 'ID'">
      <colgroup>
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.048') }}</th>
          <th>{{ $t('msg.CSBL220.002') }}</th>
          <th>{{ $t('art.CMATK100') }}</th>
          <th>{{ $t('msg.ONIM099P060.012') }}</th>
          <th>{{ $t('msg.ONEX050P060.013') }}</th>
          <th>{{ $t('msg.ARAI100_M1.006') }}</th>
          <th>{{ $t('msg.ONEX010P090.077') }}</th>
          <th>{{ $t('msg.ONEX010P090.078') }}</th>
          <th>{{ $t('msg.ONEX010P090.079') }}</th>
          <th>{{ $t('art.CMATK035') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.statusNm }}</td>
          <td>{{ $ekmtcCommon.isNotEmpty(item.pkgCd) ? item.elNo : '' }}</td>
          <td>{{ item.pkgQty }}</td>
          <td>{{ item.pkgNm }}</td>
          <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(item.grsWt, { isComma: true }) }}</td>
          <td>{{ item.prtlLdgYn }}</td>
          <td>{{ item.ldgTcnt }}</td>
          <td>{{ item.pkgtgCd }}</td>
          <td>{{ item.pkgtgPkgQty }}</td>
          <td>{{ $ekmtcCommon.isNotEmpty(item.elNoValdDt) && item.elNoValdDt.length >= 6 ? $ekmtcCommon.changeDatePattern(item.elNoValdDt) : item.elNoValdDt }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import commons from '@/api/services/commons'

export default {
  name: 'ShippingLogBLDetailBlElPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: '',
      items: [],
      polCtrCd: ''
    }
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    async init () {
      const code = await commons.getCommonsCodesCommon(['01130', '01182'])
      const blEl = this.mainData.blEl
      if (blEl !== undefined && blEl.length > 0) {
        this.items = blEl.map(item => {
          this.polCtrCd = item.polCtrCd
          if (this.$ekmtcCommon.isNotEmpty(item.pkgCd)) {
            item.pkgNm = code['01130'].filter(vo => vo.cd === item.pkgCd)[0].cdNm
          } else {
            item.pkgNm = ''
          }

          item.statusNm = code['01182'].filter(vo => vo.cd === item.updtCatCd)[0].cdNm
          return item
        })

        this.viewFlag = true
      } else {
        this.viewFlag = false
      }
    }
  }
}
</script>
